<style lang="less" scoped>
.person-center {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #070911;
  color: #dee6ff;
  &-wrapper {
    width: 77.2%;
    max-width: 1112px;
    margin: 0 auto;
    padding: 130px 0;
    &-selfInfo {
      width: 100%;
      height: 136px;
      margin-top: 10px;
      background: #0f1118;
      &-title {
        width: 100%;
        padding-top: 28px;
        display: flex;
        justify-content: flex-start;
        height: 40px;
        margin-left: 5%;
        font-size: 20px;
        // line-height: 40px;
        &-left {
          width: 4px;
          height: 20px;
          background: rgba(85, 119, 224, 1);
          border-radius: 5px;
        }
        &-right {
          width: 88%;
          font-size: 18px;
          font-weight: 400;
          color: rgba(85, 119, 224, 1);
          margin-left: 10px;
          display: flex;
          justify-content: space-between;
          p {
            margin: 0;
          }
        }
        // &-main {
        //   height: 40px;
        //   margin-left: 5%;
        //   border-left: 2px solid #ccc;
        //   padding-left: 1%;
        //   font-size: 20px;
        //   line-height: 40px;
        // }
      }
      &-item {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        height: 40px;
        line-height: 40px;
        margin-top: 6px;
        margin-left: 5%;
        font-size: 14px;
        font-weight: 500;
        color: rgba(222, 230, 255, 1);
        p {
          margin: 0;
        }
        &-left {
          width: 15%;
          p::before {
            content: " · ";
          }
        }
      }
    }
    &-selfInfo-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      &-item {
        width: 30%;
        height: 100%;
        position: relative;
        margin-top: 16px;
        overflow: hidden;
        cursor: pointer;
        img {
          width: 100%;
          width: 348px;
          height: 200px;
          overflow: hidden;
        }
        &-hover {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(7, 9, 17, 0.59);
          &-title {
            width: 100%;
            text-align: right;
            img {
              width: 46px;
              height: 45px;
            }
          }
          &-main {
            width: 80%;
            height: 43px;
            margin-left: 10%;
            margin-top: 17%;
            cursor: pointer;
            display: flex;
            &-left {
              width: 20%;
              img {
                width: 38px;
                height: 38px;
              }
            }
            &-right {
              width: 80%;
              text-align: left;
              p:nth-child(1) {
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 1px;
                margin: 0;
              }
              p:nth-child(2) {
                font-size: 10px;
                letter-spacing: 0px;
                height: 52px;
                overflow: scroll;
              }
            }
          }
        }
      }
    }
  }
  .sure-button {
    width: 140px;
    height: 40px;
    margin: 0 auto;
    text-align: center;
    line-height: 40px;
    letter-spacing: 0;
    margin-top: 32px;
    cursor: pointer;
    background: linear-gradient(
      211deg,
      rgba(248, 40, 108, 1) 0%,
      rgba(249, 98, 47, 1) 100%
    );
    border-radius: 29px;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
  }
  /deep/ .el-input__inner {
    height: 30px;
    border: 1px solid #999;
    border-radius: 0px;
    background: #070911;
  }
  /deep/ .el-checkbox__inner {
    width: 16px;
    height: 16px;
    background: rgba(15, 17, 24, 1);
    border: 1px solid rgba(222, 230, 255, 0.3);
  }
}
</style>

<template>
  <div class="person-center">
    <div class="person-center-wrapper">
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            <p>
              请选择查看品牌的产品类型
            </p>
            <p>
              已推荐 {{selected}}/10 个名品
            </p>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item">
          <el-checkbox-group v-model="type">
            <el-checkbox label="消费名品">消费名品</el-checkbox>
            <el-checkbox label="文旅名品">文旅名品</el-checkbox>
            <el-checkbox label="农副产品">农副产品</el-checkbox>
            <el-checkbox label="其他">其他</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo-container">
        <div class="person-center-wrapper-selfInfo-container-item"
             v-for="(item, index) in topBrandsListCopy"
             :key="index">
          <img :src="'/api/static/'+item.hover"
               alt="">
          <div class="person-center-wrapper-selfInfo-container-item-hover"
               @click="toDetail(item)">
            <div class="person-center-wrapper-selfInfo-container-item-hover-title">
              <img :src="item.checked ? require('../assets/checked.png') : require('../assets/uncheck.png')"
                   alt=""
                   @click.stop="checkedImg(item)">
            </div>
            <div class="person-center-wrapper-selfInfo-container-item-hover-main">
              <div class="person-center-wrapper-selfInfo-container-item-hover-main-left">
                <img :src="'/api/static/'+item.logo"
                     alt="">
              </div>
              <div class="person-center-wrapper-selfInfo-container-item-hover-main-right">
                <p>
                  {{item.brand_name}}
                </p>
                <p>
                  {{item.description}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sure-button"
           @click="recommendT">
        保存推荐
      </div>
    </div>
    <Common-footer :style="{background: '#0F1118'}"></Common-footer>
  </div>
</template>

<script>
import CommonFooter from '../components/common-footer';

export default {
  name: 'person-center',
  data () {
    return {
      type: [],
      topBrandsList: [],
      topBrandsListCopy: [],
      clickItem: null
    }
  },
  created () {
    if (!this.$Cookies.get('cecc-user')) {
      this.$router.push({
        name: 'login'
      })
    }
    this.$api.getAdmin_design_products().then(res => {
      this.topBrandsList = res.products.map(item => {
        return {
          id: item.id,
          brand_name: item.brand_name,
          description: item.detail.explain,
          adv: item.detail.adv,
          logo: item.detail.logoImg,
          hover: item.detail.previewUrl || 'image/brand_ad/002_cB3nXbYrbU2P2MbR91EBGUdCncPQNTpY.jpg',
          hover1: item.detail.previewUrl1,
          hover2: item.detail.previewUrl2,
          checked: item.recommend,
          product_type: item.product_type
        }
      });
      this.topBrandsListCopy = this.topBrandsList;
    })
  },
  mounted () {
  },
  computed: {
    selected () {
      return this.topBrandsList.filter(item => item.checked).length;
    }
  },
  watch: {
    type () {
      if (this.type.length === 0) {
        this.topBrandsListCopy = this.topBrandsList;
      } else {
        this.topBrandsListCopy = this.topBrandsList.filter(item => this.type.includes(item.product_type));
      }
    },
    selected () {
      if (this.selected > 10) {
        this.$message.warning('您最多可以推荐十个作品');
        let findIndex = this.topBrandsList.findIndex(item => item.id === this.clickItem.id);
        this.topBrandsList[findIndex].checked = false;
      }
    }
  },
  methods: {
    saveUserInfo () {
      if (!this.moreTips.includes('agree')) {
        this.$message.warning(this.$t('tips2'));
        return;
      }
      if (!this.userInfo.first_name || !this.userInfo.last_name || !this.userInfo.mobile || !this.userInfo.org_type || !this.userInfo.org_name || !this.userInfo.country) {
        this.$message.warning(this.$t('tips1'));
        return;
      }
      if (this.moreTips.includes('submit')) {
        this.userInfo.subscribed = true;
      }
      this.userInfo.org_job = this.userInfo[0];
      this.userInfo.full_name = this.userInfo.first_name + this.userInfo.last_name;
      this.$api.postUser_detail(this.userInfo).then(() => {
        this.$message.success(this.$t('P15'));
      })
    },
    checkedImg (item) {
      item.checked = !item.checked;
      this.clickItem = item;
    },
    toDetail (item) {
      let obj = {
        id: item.id,
        brandName: item.brand_name,
        introduce: item.description,
        explain: item.description,
        adv: item.adv,
        logoImg: item.logo,
        previewUrl: item.hover,
        previewUrl1: item.hover1,
        previewUrl2: item.hover2
      }
      this.$router.push({
        name: 'companyPreview',
        params: {
          id: item.id,
          from: 'recommend',
          brandObj: { ...obj, checked: item.checked }
        }
      })
    },
    recommendT () {
      let user = JSON.parse(this.$Cookies.get('cecc-user'));
      this.$api.putAdmin_recommend({
        city: user.city,
        product_ids: this.topBrandsList.filter(item => item.checked).map(item => item.id)
      }).then(() => {
        this.$message.success('推荐成功');
      }).catch(() => {
        this.$message.error('服务器出错了请稍后再试');
      })
    }
  },
  components: {
    CommonFooter
  }
}
</script>
